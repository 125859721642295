import { useEffect, useState } from "react";
import { getData } from "../functions/data";

import Loader from "../components/preloader";
import Layout from "../components/layout";

import Banner from "../components/banner";
import Industries from "../components/industries";
import Services from "../components/services";
import Blog from "../components/blog";
import Team from "../components/team";

import scrollHandle from "../functions/scroll";
import clickHandle from "../functions/click";
import device from "../functions/device";
import { getCookie, setCookie } from "../functions/cookie";

import { useLocation } from "react-router-dom";

const homeLink = "v2/p/get/?html_path=/";
const blogLink = "blog/";

const dataStorage = {};

let extraProps = {};

function Home({ locale, handleLocale }) {
	const [ready, setReady] = useState(false);
	const [pagePosition, setPagePosition] = useState(0);
	const location = useLocation();

	const handlePagePosition = (index) => {
		let industries = document.querySelector(".home__industries");
		let services = document.querySelector(".home__services");
		let blog = document.querySelector(".home__blog");
		let team = document.querySelector(".home__team");
		let footer = document.querySelector("footer");

		let scrolls = document.querySelectorAll(".scroll");
		let bodyHeight = 0;
		let breakpoints = [];

		scrolls.forEach((scroll) => {
			bodyHeight += scroll.offsetHeight;
			breakpoints.push(bodyHeight);
		});

		const yOffset = 50;
		const resultOffset = window.pageYOffset - yOffset;

		let desktopPositions = [
			0,
			breakpoints[0] - industries.offsetHeight - yOffset,
			breakpoints[0],
			breakpoints[1] + 10,
			breakpoints[2] + 10,
			breakpoints[3],
		];

		const mobPositions = [
			0,
			industries.getBoundingClientRect().top + resultOffset,
			services.getBoundingClientRect().top + resultOffset,
			blog?.getBoundingClientRect().top + resultOffset,
			team.getBoundingClientRect().top + resultOffset,
			footer.getBoundingClientRect().top + resultOffset,
		];

		if (!blog) mobPositions.splice(3, 1);

		if (device("mobile")) {
			window.scrollTo(0, mobPositions[index]);
		} else {
			let black = document.querySelector(".scroll-black");

			black.classList.add("white");

			setTimeout(() => {
				window.scrollTo(0, desktopPositions[index] + 1);

				setTimeout(() => {
					black.classList.remove("white");
				}, 50);
			}, 50);
		}

		let close = document.querySelector(".header__hamburger-close");

		if (document.querySelector(".header__hamburger-show")) {
			close.click();
		}

		setPagePosition(index);
	};

	function readyCheck() {
		return dataStorage.menuImagesPage && dataStorage.blog;
	}

	useEffect(() => {
		let resHomeLink = homeLink;
		let resBlogLink = blogLink;

		if (locale !== "ru") {
			resHomeLink += locale;
			resBlogLink += `?locale=${locale}`;
		}

		getData(resHomeLink).then((data) => {
			let menuImages = [];

			data.content.forEach((item) => {
				let value = item.value;

				if (item.type === "company_banner") dataStorage.banner = value;
				if (item.type === "industries") dataStorage.industries = value;
				if (item.type === "blog") dataStorage.blogInfo = value;
				if (item.type === "services") dataStorage.services = value;
				if (item.type === "team") dataStorage.team = value;

				if (value.menu_screen) {
					menuImages.push(value.menu_screen);
				}
			});

			dataStorage.menuImagesPage = menuImages;

			extraProps = {
				menuImagesPage: menuImages,
			};

			setReady(readyCheck());
		});

		getData(resBlogLink).then((data) => {
			dataStorage.blog = data;

			setReady(readyCheck());
		});

		if (
			getCookie("pagePosition") !== "" &&
			getCookie("pagePosition") !== null
		) {
			let indexFromRedirect = getCookie("pagePosition");

			// FIXME (window.onload doesn't trigger, so...)
			function recursiveNavigate() {
				if (document.querySelector(".home__industries")) {
					setCookie("pagePosition", "", 1);

					for (
						let i = 0;
						i < 4;
						i++ // FIXME (need to execute at least 4 times after redirect)
					)
						handlePagePosition(indexFromRedirect);
				} else {
					setTimeout(() => {
						recursiveNavigate();
					}, 150);
				}
			}

			recursiveNavigate();
		}

		scrollHandle();
		clickHandle();
	}, [locale, location, pagePosition]);

	return (
		<>
			{!ready ? (
				<Loader />
			) : (
				<Layout
					rootClass={`home jcc-aifs wrap ${
						locale === "en" ? "english" : "russian"
					}`}
					locale={locale}
					handleLocale={handleLocale}
					extraProps={extraProps}
					pagePosition={pagePosition}
					handlePagePosition={handlePagePosition}
				>
					<Main data={dataStorage} />
				</Layout>
			)}
		</>
	);
}

function Main({ data }) {
	return (
		<div className="home__main jcc-aifs wrap w-full">
			<div className="scroll w-full">
				<div className="scroll-body w-full">
					<Banner data={data.banner} />
					<Industries data={data.industries} />
				</div>
			</div>

			<div className="scroll w-full">
				<div className="scroll-body">
					<Services data={data.services} />
				</div>
			</div>

			{data.blog.count > 0 && (
				<div className="scroll w-full">
					<div className="scroll-body">
						<Blog data={data.blog} dataInfo={data.blogInfo} />
					</div>
				</div>
			)}

			<div className="scroll w-full">
				<div className="scroll-body w-full">
					<Team data={data.team} />
				</div>
			</div>

			<div className="scroll-black"></div>
		</div>
	);
}

export default Home;
