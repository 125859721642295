import { useState } from "react";
import { Link } from "react-router-dom";
import { getData } from "../functions/data";
import { getDate } from "../functions/date";
import { getLink } from "../functions/link";

import device from "../functions/device";

function Blog({ data, dataInfo }) {
	const [pagesData, setPagesData] = useState(data);

	const handlePages = () => {
		getData(pagesData.next, true).then((newData) => {
			let oldResults = pagesData.results;
			let newResults = newData.results;

			newData.results = [...oldResults, ...newResults];

			setPagesData(newData);
		});
	};

	return (
		<section className="home__blog jcc-aifs w-full">
			<div className="home__blog-container">
				<h2 className="home__heading">{dataInfo.title}</h2>

				<div className="home__blog-content">
					<BlogItems
						data={pagesData.results}
						dataInfo={dataInfo}
						count={pagesData.count}
					/>

					<div className="home__blog-center jcc-aic">
						{pagesData.next ? (
							<button onClick={handlePages}>
								{dataInfo.loading_btn_text}
							</button>
						) : null}
					</div>
				</div>
			</div>
		</section>
	);
}

function BlogItems({ data, dataInfo, count }) {
	let column = 1;

	const columnInc = (index) => {
		if (column === 3 || count === index + 1) {
			column = 1;

			return;
		}

		column++;
	};

	return (
		<div className="home__blog-items jcsb-aifs wrap">
			{device("desktop") ? <DesktopBlock /> : <MobileBlock />}
		</div>
	);

	function DesktopBlock() {
		return (
			<>
				<DesktopBlockItem columnIndex={1} />
				<DesktopBlockItem columnIndex={2} />
				<DesktopBlockItem columnIndex={3} />
			</>
		);
	}

	function DesktopBlockItem({ columnIndex }) {
		return (
			<div className="home__blog-column">
				{data.map((item, index) => {
					if (column === columnIndex) {
						columnInc(index);

						return (
							<BlogItem
								data={item}
								dataInfo={dataInfo}
								key={index}
							/>
						);
					}

					columnInc(index);

					return null;
				})}
			</div>
		);
	}

	function MobileBlock() {
		return (
			<div className="home__blog-column">
				{data.map((item, index) => {
					return (
						<BlogItem data={item} dataInfo={dataInfo} key={index} />
					);
				})}
			</div>
		);
	}
}

function BlogItem({ data, dataInfo }) {
	return (
		<div className="home__blog-item">
			{data.card_image ? (
				<div className="home__blog-item--image">
					<img
						src={data.card_image.url}
						alt={data.card_image.title}
					/>
				</div>
			) : null}

			<div className="home__blog-item--info">
				<h3 className="home__blog-item--title">{data.annotation}</h3>

				<div className="home__blog-item--head jcfs-aic">
					{data.author_icon && (
						<div className="blog__head-logo jcc-aic">
							<img
								src={data.author_icon.url}
								alt={data.author_icon.title}
							/>
						</div>
					)}

					<h6 className="home__blog-item--date">
						{getDate(data.published_dt)}
					</h6>
				</div>

				<p className="home__blog-item--more">
					<Link to={getLink(data.full_url)}>
						{dataInfo.detailing_btn_text}
					</Link>
				</p>
			</div>
		</div>
	);
}

export default Blog;
