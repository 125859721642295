import device from "./device";
import { animationHandle, animationUpdate, animationSetup } from "./animation";

function scrollHandle() {
	let triggerPosition = 0.25;

	let homePage = document.querySelector(".home");
	let scroll = document.querySelector(".scroll-body");

	if (homePage && scroll) {
		if (device("desktop")) {
			animationUpdate();
			animationSetup();
		}

		window.onscroll = () => {
			let homePage = document.querySelector(".home");

			if (homePage && device("desktop")) {
				let header = document.querySelector("header");
				let banner = document.querySelector(".home__banner");
				let bannerHeight = banner.offsetHeight;
				let currentScroll = document.documentElement.scrollTop;

				if (currentScroll > bannerHeight * triggerPosition) {
					header.classList.add("header__back");
				} else {
					header.classList.remove("header__back");
				}

				animationHandle();
			}
		};
	} else {
		setTimeout(() => scrollHandle(), 100); // FIXME (without it triggers before full DOM was loaded)
	}
}

export default scrollHandle;
