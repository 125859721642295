function getDate(srcDate) {
	let date = new Date(srcDate);

	return (
		("0" + date.getDate()).slice(-2) +
		"." +
		("0" + (date.getMonth() + 1)).slice(-2) +
		"." +
		date.getFullYear() +
		" " +
		("0" + date.getHours()).slice(-2) +
		":" +
		("0" + date.getDate()).slice(-2)
	);
}

export { getDate };
