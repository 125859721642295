let breakpoints = [];

const maxZIndex = 8;

let scrolls;
let scrollBodies;
let bodyHeight;

function animationHandle() {
	let currentScroll = window.pageYOffset;
	let black = document.querySelector(".scroll-black");
	let header = document.querySelector("header");

	if (!document.querySelector(".home")) {
		document.body.style.height = "auto";

		return;
	}

	animationUpdate();

	breakpoints.forEach((breakpoint, index) => {
		let elemTop = breakpoint[0];
		let top = breakpoint[1];
		let bottom = breakpoint[2];

		if (currentScroll >= elemTop && currentScroll <= bottom) {
			if (currentScroll > top) {
				scrolls[index].style.transform = `translateY(${
					top - currentScroll
				}px)`;

				black.style.zIndex = maxZIndex - 1;
				black.style.opacity =
					1 - (currentScroll - top) / window.innerHeight;

				header.style.opacity = 0;
			} else {
				scrollBodies[index].style.transform = `translateY(${
					elemTop - currentScroll
				}px)`;

				header.style.opacity = 1;

				scrolls[index].style.transform = "translateY(0px)";
			}

			scrolls.forEach((scroll, scrollIndex) => {
				let newIndex = 0;

				if (scrollIndex === index) {
					newIndex = maxZIndex;
				} else {
					newIndex =
						parseInt(maxZIndex) - Math.abs(scrollIndex - index) - 1;

					if (scrollIndex < index) {
						scrollBodies[
							scrollIndex
						].style.transform = `translateY(-${
							breakpoints[scrollIndex][1] -
							breakpoints[scrollIndex][0]
						}px)`;
					} else {
						scrollBodies[scrollIndex].style.transform =
							"translateY(0px)";
					}
				}

				scroll.style.zIndex = newIndex;
			});
		}
	});
}

function animationUpdate() {
	scrolls = document.querySelectorAll(".scroll");
	scrollBodies = document.querySelectorAll(".scroll-body");
	bodyHeight = 0;

	scrolls.forEach((scroll, index) => {
		scroll.style.height = scrollBodies[index].offsetHeight + "px";
	});

	scrolls.forEach((scroll, index) => {
		let zIndex = maxZIndex - index;

		bodyHeight += scroll.offsetHeight;

		breakpoints[index] = [
			bodyHeight - scroll.offsetHeight, // distance from the top of an element to the top of the page
			bodyHeight - window.innerHeight, // top window distance to the top of the page when we scrolled to the end of an element
			bodyHeight, // bottom window distance to the top of the page when we scrolled to the end of an element
		];

		scroll.style.zIndex = zIndex;
	});

	document.body.style.height = bodyHeight + "px";
}

function animationSetup() {
	scrollBodies = document.querySelectorAll(".scroll-body");

	// append footer to the last .scroll item & fix clients
	scrollBodies[scrollBodies.length - 1].appendChild(
		document.querySelector("footer")
	);

	document
		.querySelector(".home")
		.appendChild(document.querySelector(".home__industries-clients"));

	window.scrollTo(0, 10000);
	window.scrollTo(0, 0);
}

export { animationHandle, animationUpdate, animationSetup };
