import { Switch, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCookie, setCookie } from "./js/functions/cookie";

import Home from "./js/pages/home";
import Blog from "./js/pages/blog";
import NotFound from "./js/pages/404";

function App() {
	const [locale, setLocale] = useState(null);

	const handleLocale = () => {
		let newLang = "en";

		if (locale === "en") newLang = "ru";

		setCookie("locale", newLang, 365);

		window.location.reload();
	};

	useEffect(() => {
		let cookieLang = getCookie("locale");

		if (cookieLang === "") {
			setCookie("locale", "ru", 365);
		}

		if (cookieLang !== locale) {
			setLocale(cookieLang);
		}
	}, [locale]);

	function SwitchBlock() {
		return (
			<Switch>
				<Route exact path="/">
					<Home locale={locale} handleLocale={handleLocale} />
				</Route>

				<Route path="/blog/:locale?/:slug">
					<Blog locale={locale} />
				</Route>

				<Route>
					<NotFound />
				</Route>
			</Switch>
		);
	}

	return <>{locale && <SwitchBlock />}</>;
}

export default App;
