import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { setCookie } from "../functions/cookie";
import device from "../functions/device";

import Presentation from "./presentation";

function Header({
	data,
	menuImages = null,
	handleLocale = null,
	pagePosition = null,
	handlePagePosition = null,
}) {
	const [hamburger, setHamburger] = useState(false);
	const [presentation, setPresentation] = useState(false);

	const history = useHistory();

	const handleHamburger = () => {
		let header = document.querySelector("header");

		header.classList.toggle("header__hamburger-show");

		setHamburger(!hamburger);
	};

	const handlePresentation = () => {
		let header = document.querySelector("header");

		header.classList.toggle("header__presentation-show");

		setPresentation(!presentation);
	};

	if (!handlePagePosition) {
		handlePagePosition = (index) => {
			setCookie("pagePosition", index);

			history.push({ pathname: "/" });
		};
	}

	return (
		<header className="header jcc-aic w-full">
			<div className="header__container jcsb-aic">
				<div className="header__logo ">
					<Link to="/">
						<img src={data.logo.url} alt={data.logo.title} />
					</Link>
				</div>
				<div className="header__action jcsb-aic">
					<div className="header__move jcsb-aic">
						<div className="header__action-ask relative">
							<div
								onClick={handlePresentation}
								className="header__action-ask--block jcsb-aic"
							>
								<img
									src="/media/paperclip.svg"
									alt="paperclip"
								/>

								<h3>{data.ask}</h3>
							</div>

							{device("desktop") && (
								<Presentation
									data={data.presentation}
									handlePresentation={handlePresentation}
								/>
							)}
						</div>

						<div
							className="header__action-lang"
							onClick={handleLocale ? handleLocale : null}
						>
							{data.locale}
						</div>
					</div>

					{menuImages && (
						<div
							className="header__action-hamburger"
							onClick={handleHamburger}
						>
							<img src="/media/hamburger.svg" alt="hamburger" />
						</div>
					)}
				</div>
			</div>

			{menuImages && (
				<Hamburger
					menuImages={menuImages}
					handleHamburger={handleHamburger}
					pagePosition={pagePosition}
					handlePagePosition={handlePagePosition}
				/>
			)}
		</header>
	);
}

function Hamburger({
	menuImages,
	handleHamburger,
	pagePosition,
	handlePagePosition,
}) {
	function isItemWhite(title) {
		const white = [
			"Услуги",
			"Отрасли",
			"Свяжитесь с нами",
			"Services",
			"Credentials",
			"Get in touch",
		];

		if (white.includes(title)) return true;
	}

	return (
		<div className="header__hamburger fixed">
			<div
				className={`header__hamburger-close w-full ${
					device("desktop") ? "jcfs-aic" : "jcc-aic"
				}`}
				onClick={handleHamburger}
			>
				<img src="/media/close.png" alt="close" />
			</div>

			<div className="header__hamburger-items">
				{menuImages.map((item, index) => {
					return device("desktop") ? (
						<div
							className={`header__hamburger-item relative ${
								pagePosition === index
									? "header__hamburger-item--current"
									: ""
							}`}
							key={index}
							onClick={() => handlePagePosition(index)}
						>
							<img src={item.url} alt={item.title} />

							<button
								className={`header__hamburger-item--title absolute ${
									isItemWhite(item.title)
										? "header__hamburger-item--white"
										: ""
								}`}
							>
								{item.title}
							</button>
						</div>
					) : (
						<div
							className={`header__hamburger-item jcc-aic ${
								pagePosition === index
									? "header__hamburger-item--current"
									: ""
							}`}
							key={index}
							onClick={() => handlePagePosition(index)}
						>
							<button className="header__hamburger-item--title">
								{item.title}
							</button>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default Header;
