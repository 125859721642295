import { useState } from "react";
import device from "../functions/device";

function Industries({ data }) {
	const [showClients, setShowClients] = useState(false);

	const clientsFilter = (event) => {
		let index = event.target.dataset.index;
		let groups = document.querySelectorAll(
			".home__industries-clients--images"
		);

		if (!showClients) {
			groups.forEach((group) => {
				if (group.dataset.index === index) {
					group.classList.remove("hidden");

					return;
				}

				group.classList.add("hidden");
			});
		}

		setShowClients(!showClients);
	};

	const industries = data;

	let rows = [];
	let cards = [];
	let title = "";

	industries.industries.forEach((industry) => {
		rows.push({
			image: industry.icon,
			title: industry.title,
		});

		cards.push({
			size: industry.size,
			image: industry.image,
			title: industry.title,
			link_text: industry.link_text,
			icon: industry.icon,
			clients: industry.customers,
		});

		title = industry.customers_title;
	});

	return (
		<section className="home__industries jcc-aifs w-full wrap">
			<Clients
				title={title}
				cards={cards}
				showClients={showClients}
				clientsFilter={clientsFilter}
			/>

			<div className="home__industries-logos w-full jcc-aic">
				<div className="home__industries-logos--container jcsb-aic">
					<h2 className="home__heading">{industries.title}</h2>

					<div className="home__industries-logos--items jcsb-aic">
						{rows.map((row, index) => {
							return (
								<LogoItem
									row={row}
									clientsFilter={clientsFilter}
									key={index}
									index={index}
								/>
							);
						})}
					</div>
				</div>
			</div>

			<div className="home__industries-main">
				<div className="home__industries-main--container">
					<h2 className="home__heading">{industries.title}</h2>

					<div className="home__industries-main--items jcc-aic wrap">
						{cards.map((industry, index) => {
							return (
								<MainItem
									industry={industry}
									clientsFilter={clientsFilter}
									key={index}
									index={index}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</section>
	);
}

function LogoItem({ row, index, clientsFilter }) {
	return (
		<div
			className="home__industries-logos--item jcc-aic"
			onClick={clientsFilter}
			data-index={index}
		>
			<img src={row.image.url} alt={row.image.title} />

			<h3 data-index={index}>{row.title}</h3>
		</div>
	);
}

function MainItem({ industry, index, clientsFilter }) {
	return (
		<div
			className={`home__industries-main--item relative ${
				industry.size === "2" ? "home__industries-main--item-big" : ""
			}`}
		>
			<img src={industry.image.url} alt={industry.image.title} />

			<div
				className={`home__industries-main--info absolute wh-full ${
					device("mobile") ? "jcc-aifs" : "jcc-aic"
				} wrap`}
			>
				{device("mobile") ? (
					<div className="home__industries-main--icon jcc-aife">
						<img
							src={industry.icon.url}
							alt={industry.icon.title}
						/>
					</div>
				) : null}

				<div className="home__industries-main--text jcc-aifs wrap">
					<h3 className="w-full">{industry.title}</h3>

					<button onClick={clientsFilter} data-index={index}>
						{industry.link_text}
					</button>
				</div>
			</div>
		</div>
	);
}

function Clients({ title, cards, showClients, clientsFilter }) {
	return (
		<div
			className={`home__industries-clients fixed wh-full jcc-aifs ${
				showClients ? "home__industries-clients--show" : ""
			}`}
		>
			<div className="container">
				<div className="home__industries-clients--head jcsb-aic">
					<h2 className="home__industries-heading">{title}</h2>

					<div
						className="home__industries-clients--close"
						onClick={clientsFilter}
					>
						<img src="/media/close.png" alt="close" />
					</div>
				</div>
				<div className="home__industries-clients--body jcfs-aifs wrap">
					{cards.map((industry, index) => {
						return (
							<Client
								client={industry.clients}
								clientIndex={index}
								key={index}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}

function Client({ client, clientIndex }) {
	return (
		<div
			className={`home__industries-clients--images ${
				device("desktop") ? "jcfs-aifs" : "jcsb-aifs"
			} wrap`}
			data-index={clientIndex}
		>
			{client.icons.map((icon, index) => {
				return (
					<div
						className="home__industries-clients--image jcc-aic"
						key={index}
					>
						<img src={icon.url} alt={icon.title} />
					</div>
				);
			})}
		</div>
	);
}

export default Industries;
