import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getData } from "../functions/data";
import { getDate } from "../functions/date";
import { getLink } from "../functions/link";

import Loader from "../components/preloader";
import Layout from "../components/layout";

import HTMLParser from "html-react-parser";

const dataStorage = {};

const apiLink = "v2/p/get/?html_path=";

function Blog({ locale }) {
	const [ready, setReady] = useState(false);
	const slug = useLocation().pathname;

	function readyCheck() {
		return dataStorage.blog;
	}

	useEffect(() => {
		let resPageLink = apiLink;

		resPageLink += slug;

		getData(resPageLink).then((data) => {
			dataStorage.blog = data;

			setReady(readyCheck());
		});

		// fixes for redirect from homepage
		window.onscroll = null;
		document.body.style.height = "auto";
	}, [slug]);

	return (
		<>
			{!ready ? (
				<Loader />
			) : (
				<Layout
					rootClass={`blog w-full jcc-aifs wrap ${
						locale === "en" ? "english" : "russian"
					}`}
					locale={locale}
				>
					<Main data={dataStorage.blog} />
				</Layout>
			)}
		</>
	);
}

function Main({ data }) {
	return (
		<div className="blog__content">
			<div className="blog__head jcfs-aic">
				{data.author_icon && (
					<div className="blog__head-logo jcc-aic">
						<img
							src={data.author_icon.url}
							alt={data.author_icon.title}
						/>
					</div>
				)}

				<h6 className="blog__head-date">
					{getDate(data.published_dt)}
				</h6>
			</div>

			<h3 className="blog__title">{data.annotation}</h3>

			{data.content_html && (
				<div className="blog__text">
					{HTMLParser(data.content_html)}
				</div>
			)}

			{data.recommendations && (
				<Recommendations data={data.recommendations} info={data} />
			)}
		</div>
	);
}

function Recommendations({ data, info }) {
	return (
		<div className="blog__recommendations">
			{data.map((item, index) => {
				return (
					<div className="blog__recommendations-item" key={index}>
						<h3 className="home__blog-item--title">
							{item.annotation}
						</h3>

						<h6 className="home__blog-item--date">
							{getDate(item.published_dt)}
						</h6>

						<p className="home__blog-item--more">
							<Link to={getLink(item.full_url)}>
								{info.recommendations_btn}
							</Link>
						</p>
					</div>
				);
			})}
		</div>
	);
}

export default Blog;
