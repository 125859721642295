function NotFound() {
	return (
		<>
			<h1 style={{ textAlign: "center", margin: "8vh 0 8vh" }}>
				<strong>nginx error!</strong>
			</h1>

			<div class="content" style={{ textAlign: "center" }}>
				<h3>The page you are looking for is not found.</h3>
			</div>
		</>
	);
}

export default NotFound;
