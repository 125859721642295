import { useEffect, useState } from "react";
import { getData } from "../functions/data";
import Footer from "./footer";
import Header from "./header";
import Loader from "./preloader";

const homeLink = "v2/p/get/?html_path=/";
const infoLink = "info/";

const dataStorage = {};

function Layout({
	rootClass,
	locale,
	handleLocale,
	extraProps = {},
	pagePosition = null,
	handlePagePosition = null,
	children,
}) {
	const [ready, setReady] = useState(false);

	function readyCheck() {
		return dataStorage.header && dataStorage.menuImagesPage;
	}

	useEffect(() => {
		let resHomeLink = homeLink;
		let resInfoLink = infoLink;

		if (locale !== "ru") {
			resHomeLink += locale;
			resInfoLink += `?locale=${locale}`;
		}

		if (extraProps.menuImagesPage) {
			dataStorage.menuImagesPage = extraProps.menuImagesPage;
		} else {
			getData(resHomeLink).then((data) => {
				let menuImages = [];

				data.content.forEach((item) => {
					let value = item.value;

					if (value.menu_screen) {
						menuImages.push(value.menu_screen);
					}
				});

				dataStorage.menuImagesPage = menuImages;

				setReady(readyCheck());
			});
		}

		getData(resInfoLink).then((data) => {
			let menuImages = [];

			dataStorage.footer = {
				title: data.footer_title,
				footer: data.footer,
				presentation: data.presentation,
				ask: data.request_btn_text,
			};

			dataStorage.header = {
				logo: data.site_logo,
				ask: data.request_btn_text,
				locale: data.other_locale,
				presentation: data.presentation,
			};

			menuImages.push(data.menu_screen);
			dataStorage.menuImagesInfo = menuImages;

			setReady(readyCheck());
		});
	}, [extraProps.menuImagesPage, locale]);

	return (
		<div className={rootClass}>
			{!ready ? (
				<Loader />
			) : (
				<>
					<Header
						data={dataStorage.header}
						menuImages={[
							...dataStorage.menuImagesPage,
							...dataStorage.menuImagesInfo,
						]}
						extraProps={extraProps}
						pagePosition={pagePosition}
						handlePagePosition={handlePagePosition}
						handleLocale={handleLocale}
					/>

					{children}

					<Footer data={dataStorage.footer} />
				</>
			)}
		</div>
	);
}

export default Layout;
