import device from "../functions/device";

function Services({ data }) {
	const services = data;

	return (
		<section className="home__services jcc-aifs w-full">
			<div className="home__services-container">
				<h2 className="home__heading">{services.title}</h2>

				<p>{services.description}</p>

				<div className="home__services-items jcsb-aifs wrap">
					{services.services.map((service, index) => {
						return <Service service={service} key={index} />;
					})}
				</div>
			</div>
		</section>
	);
}

function Service({ service }) {
	return (
		<div
			className={`home__services-item column ${
				device("mobile") ? "jcsb-aifs" : "jcsb-aic"
			}`}
		>
			<div
				className={`home__services-title jcc-aife ${
					// figma kludge
					service.title === "Content  Marketing"
						? "home__services-title--little"
						: ""
				}`}
			>
				<h3 className="home__services-title--block">{service.title}</h3>
			</div>

			<div className="home__services-logo">
				<img src={service.image.url} alt={service.image.title} />
			</div>
		</div>
	);
}

export default Services;
