import { useState } from "react";
import { postData } from "../functions/data";

const formEndpoint = "request/";

function Presentation({ data, handlePresentation }) {
	const [formState, setFormState] = useState(false);
	const [successText, setSuccessText] = useState(data.result_text);

	const handleSubmit = (event) => {
		event.preventDefault();

		let formData = new FormData(event.currentTarget);

		postData(formEndpoint, formData).then((result) => {
			if (result.success) {
				setSuccessText(data.result_text);
			} else {
				setSuccessText("Error!");
			}

			setFormState(true);
		});
	};

	const handlePresentationClose = () => {
		handlePresentation();

		setTimeout(() => {
			document.querySelector(".presentation__form").reset();

			setFormState(false);
		}, 500);
	};

	return (
		<div className="presentation">
			<div className="presentation__close jcfe-aic">
				<img
					onClick={handlePresentationClose}
					src="/media/close-presentation.svg"
					alt="close"
				/>
			</div>

			<form className="presentation__form" onSubmit={handleSubmit}>
				<input
					type="text"
					name="name"
					required
					placeholder={data.name_ph}
				/>
				<input
					type="email"
					name="email"
					required
					placeholder={data.email_ph}
				/>

				{!formState ? (
					<button>{data.submit_btn}</button>
				) : (
					<p className="presentation__form-text">{successText}</p>
				)}
			</form>
		</div>
	);
}

export default Presentation;
