import axios from "axios";

let api = "/api/";

if (process.env.NODE_ENV === "development") {
	api = "https://newla.ru/api/";
}

async function getData(endpoint, full = false) {
	let link = api + endpoint;

	if (full) {
		link = endpoint;
	}

	const query = axios.get(link);
	const { data } = await query;

	query.catch((error) => {
		throw new Error(error);
	});

	return new Promise((resolve) => resolve(data));
}

async function postData(endpoint, formData) {
	let link = api + endpoint;

	const query = axios.post(link, formData);
	const { data } = await query;

	query.catch((error) => {
		throw new Error(error);
	});

	return new Promise((resolve) => resolve(data));
}

export { getData, postData };
