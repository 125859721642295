import { useState } from "react";

function Banner({ data }) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<section
			className={`home__banner jcc-aifs w-full relative ${
				isOpen ? "home__banner-open" : ""
			}`}
		>
			<>
				<div className="home__banner-container jcfs-aife w-full wrap">
					<div className="home__banner-info relative">
						<h1>{data.title}</h1>

						<p>{data.subtitle}</p>
					</div>

					<div className={`home__banner-about w-full`}>
						<button onClick={() => setIsOpen(!isOpen)}>
							{data.link_text}
						</button>

						<p>{data.description}</p>
					</div>
				</div>

				<div className="lines">
					<div className="line"></div>
					<div className="line"></div>
					<div className="line"></div>
				</div>
			</>
		</section>
	);
}

export default Banner;
