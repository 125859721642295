function Team({ data }) {
	const team = data;

	return (
		<section className="home__team jcc-aifs w-full">
			<div className="home__team-container">
				<h2 className="home__heading">{team.title}</h2>

				<div className="home__team-items jcsb-aifs wrap">
					{team.team.map((item, index) => {
						return <TeamMember item={item} key={index} />;
					})}
				</div>
			</div>
		</section>
	);
}

function TeamMember({ item }) {
	return (
		<div className="home__team-item column jcfs-aic">
			<div className="home__team-item--image">
				<img src={item.image.url} alt={item.image.title} />
			</div>
			<div className="home__team-item--info">
				<div className="home__team-item--role">{item.position}</div>

				<div className="home__team-item--name">
					{item.employee_name}
				</div>
			</div>
		</div>
	);
}

export default Team;
