function clickHandle() {
	document.addEventListener("click", (e) => {
		let show = document.querySelector(".header__hamburger-show");
		let close = document.querySelector(".header__hamburger-close");

		if (show && !e.target.closest("header")) {
			close.click();
		}
	});
}

export default clickHandle;
