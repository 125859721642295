import Presentation from "./presentation";
import device from "../functions/device";
import { useState } from "react";

function Footer({ data }) {
	const [presentation, setPresentation] = useState(false);

	const handlePresentation = () => {
		let footer = document.querySelector("footer");

		footer.classList.toggle("footer__presentation-show");

		setPresentation(!presentation);
	};

	const title = data.title;
	const left = {};
	const right = data.footer[1]?.value;

	data.footer[0].value.forEach((item) => {
		if (item.type === "email") left.mail = item;
		if (item.type === "tel") left.tel = item;
		if (item.type === "social") left.social = item.value;
		if (item.type === "text") left.text = item.value;
	});

	return (
		<footer className="footer w-full jcc-aifs relative">
			<div className="footer__container">
				<h1>{title}</h1>

				<div className="footer__content jcsb-aifs wrap">
					<div className="footer__left">
						<div className="footer__info">
							<a
								href={`tel:${left.tel.value}`}
								className="footer__info-item jcsb-aic"
							>
								<img src="/media/phone.svg" alt="phone" />

								<p className="bold">{left.tel.value}</p>
							</a>
							<a
								href={`mailto:${left.mail.value}`}
								className="footer__info-item jcsb-aic"
							>
								<img src="/media/mail.svg" alt="mail" />

								<p className="bold">{left.mail.value}</p>
							</a>
							<div className="footer__ask">
								<div
									className="footer__ask-block jcsb-aic"
									onClick={handlePresentation}
								>
									<img
										src="/media/paperclip_white.svg"
										alt="paperclip"
									/>

									<h3>{data.ask}</h3>
								</div>

								{device("mobile") && (
									<Presentation
										data={data.presentation}
										handlePresentation={handlePresentation}
									/>
								)}
							</div>
						</div>

						<div className="footer__social jcfs-aic">
							{left.social.map((source, index) => {
								return (
									<a href={source.link} key={index}>
										<img
											src={source.icon.url}
											alt={source.icon.title}
										/>
									</a>
								);
							})}
						</div>

						<div className="footer__text">
							<p className="bold">{left.text.title}</p>
							<p>{left.text.text}</p>
						</div>
					</div>

					<div className="footer__right">
						{right &&
							right.map((item, index) => {
								return (
									<div className="footer__text" key={index}>
										{item.value.title ? (
											<p className="bold">
												{item.value.title}
											</p>
										) : null}

										{item.value.text ? (
											<p>{item.value.text}</p>
										) : null}
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
